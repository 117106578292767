<template>
    <div class="rightLink">
      <div class="bottomDiv">
        <div class="index_title" style="border-bottom: none">订单退费</div>
        <div class="formDiv" style="width: 70vw; margin-left: 8vw;">
            <el-form label-position="left" label-width="90px">
              <!-- <el-row :gutter="20">
                <el-col :span="6"> -->
                  <el-form-item class="item1" label="订单号:">
                    <el-input
                      v-model="FormData.order_id"
                      placeholder="订单编号"
                    ></el-input>
                  </el-form-item>
                <!-- </el-col>
                <el-col :span="6"> -->
                  <el-form-item class="item1" label="用户手机号:">
                    <el-input
                      v-model="FormData.phone"
                      placeholder="请输入设备编号"
                    ></el-input>
                  </el-form-item>
                <!-- </el-col>
                <el-col :span="6"> -->
                  <el-form-item class="item1" label="设备编号:">
                    <el-input
                      v-model="FormData.device_id"
                      placeholder="请输入设备编号"
                    ></el-input>
                  </el-form-item>
                  
                <!-- </el-col>
             
            </el-row> -->
          <!-- </el-form>
            <el-form label-position="left" label-width="90px">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="车型:">
                  <el-select v-model="FormData.type_id" placeholder="请选择充电类型">
                    <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                  </el-select>
                </el-form-item>
                </el-col>
                <el-col v-show="isadmin" :span="6">
                  <el-form-item label="运营商:">
                    <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                      <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="订单时间：" class="time_pick">
                    <el-date-picker
                      style="height: 40px; width: 100%;"
                      v-model="FormData.orderTime"
                      type="daterange"
                      unlink-panels
                      range-separator=" - "
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      size="small"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                    >
                    </el-date-picker>
                  </el-form-item>
              </el-col>
              <el-col :span="1">
                <div class="BtnSearch" @click="searchBtn()">查询</div>
              </el-col> -->
              <!-- <el-col :span="1" :offset="1">
                <div class="BtnSearch" @click="handleExcel()">导出</div>
              </el-col>
              <el-col :span="1" :offset="1">
                <div class="BtnSearch" @click="handlePrint()">打印</div>
              </el-col>
            </el-row> -->
          </el-form>
          <div class="BtnSearch" style="width: 20vw; margin: 5px 25vw;" @click="searchBtn()">查询</div>
          
        </div>
        <!-- 表格部分 -->
        <div class="tableDiv">
        <el-table
          align="center"
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height= "480px"
          style="
            width: 90%;
            margin: 20px auto;
            overflow-y: auto;
          "
        >
            
            <el-table-column label="订单编号" prop="order_id" />
            <el-table-column label="手机号" prop="phone" />   
            <el-table-column label="设备编号" prop="device_id" />
            <!-- <el-table-column label="充电站名称" prop="station_name" />
            <el-table-column label="充电类型" prop="type_id">
              <template #default="scope">
                <span v-if="scope.row.type_id == 2">电动车</span>
                <span v-if="scope.row.type_id == 4">电动汽车</span>
              </template>
            </el-table-column>
            <el-table-column label="充电端口" prop="socket" width="80" />
            <el-table-column label="开始时间" prop="start_time" :formatter="formatDate"/> -->
            <!-- <el-table-column label="计划充电时长" prop="pay_money" /> -->
            <el-table-column label="支付金额" prop="pay_money" />
            <el-table-column label="支付方式" prop="pattern_of_payment" />
            <!-- <el-table-column label="所属运营商" prop="username" /> -->
            <el-table-column
              label="操作"
              width="90"
              align="center"
              fixed="right" 
            >
              <template #default="scope">
                <el-button round color='#42a7a9' plain size="mini" @click="torefund(scope.$index, scope.row)">退费</el-button>
               
              </template>
            </el-table-column>
          </el-table>
          
        </div>
        <div class="pagination">
          <p>支付实时订单数：<span >{{ tableData1.count_order }}</span></p>
          <p>实时订单支付金额：<span>{{ tableData1.sum_order }}</span></p>
        </div>
        <el-dialog
          v-model="Dialog.editLevy"
          title="订单退费"
          width="90vw"
          :before-close="closeDialog"
          class="dialog"
        >
          <div class="formDiv">
            <el-form
          ref="FormRules"
          :rules="rules"
          :model="FormData"
          label-position="right"
          label-width="80px"
        >
              <el-form-item label="订单编号:" style="width: 65vw;">
                <el-input
                  disabled
                  v-model="Dialog.DialogData.order_id"
                ></el-input>
              </el-form-item>
              <el-form-item label="支付金额:" style="width: 65vw;">
                <el-input
                  disabled
                  v-model="Dialog.DialogData.pay_money"
                ></el-input>
              </el-form-item>
                <el-form-item label="退费金额:" style="width: 65vw;">
                  <el-select style="width: 100%;" v-model="Dialog.DialogData.money" placeholder="请选择退款金额" @change="handlemoney">
                    <el-option v-for="moneys in moneyList" :key="moneys.value" :value="moneys.value" :label="moneys.label"/>
                    <!-- </el-option> -->
                  </el-select>
              </el-form-item>
           
              <el-form-item label="退费金额:" style="width: 65vw;" v-show="isshow">
                <el-input
                  type="number"
                  v-model="Dialog.DialogData.refund_fee"
                  placeholder="请输入退费金额"
                ></el-input>
              </el-form-item>
              <el-form-item label="退费原因:" style="width: 65vw;">
                <el-input 
                  v-model="Dialog.DialogData.refund_reason"
                  placeholder="请输入退费原因"
                ></el-input>
              </el-form-item>
      
        
        </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog">确认</el-button>
            </span>
          </template>
        </el-dialog>
        
      </div>
    </div>
  </template>
  <script>
   // import { onMounted } from "vue";
   import { onMounted, reactive, toRefs } from "vue-demi";
  import { sel_history_order,start_chage_yun,re_money1,operator_data } from "@/request/api";
  import { ElMessage } from "element-plus";
  import ProvinceCityCountry from '../../assets/json/address'
  export default {
    name: "UserRecord",
    setup() {
      const data = reactive({
        //表单数据
        FormData: {
          orderTime:['',''],
        },
        isadmin:false,
        users:[],
        refunds:{},
        isshow:false,
        values:'',
        tableData: [],
        tableData1: {},
        carList:[
            {
              value:2,
              label:'电动车'
            },
            {
              value:4,
              label:'电动汽车'
            }
          ],
          moneyList:[
            {
              value:0,
              label:'全额退费'
            },
            {
              value:1,
              label:'手动输入'
            }
          ],
          Dialog: {
            editLevy: false,
            DialogData: {},
          },
          car:'',

        
        loading: false,
        provinceData: ProvinceCityCountry.address,
        //   分页
        currentPage:1,
        pageSize:20,
        total:400,
        checked:0,
        small:true,
  
      });
      const rounding=(row,column)=> {
        return parseFloat(row[column.property]?row[column.property]:'0').toFixed(2)
      }
      const searchBtn = () => {
      
        console.log(data.FormData,'data.FormData');
        const stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0]: undefined;
        const endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : undefined;
        const dataa = {
          username:data.FormData.username,
          operator_id : sessionStorage.getItem('userID'),
          phone:data.FormData.phone,
          type_id:data.FormData.type_id,
          device_id:data.FormData.device_id,
          order_id:data.FormData.order_id,
          start_execute_date: stDate,
          stop_execute_date: endDate
        }
        console.log(dataa,'data.FormData11');
     
        data.loading = true;
        sel_history_order(dataa).then((res) => {
          console.log(res,'实时订单列表');
          if (res) {
            data.loading = false;
            if (res.code == 200) {
              data.tableData1 = res;  
              data.tableData = res.data;  
              data.total = res.data.length;
            } else {
              ElMessage.error(res.msg);                                
            }
          } else {
            ElMessage.error("数据获取失败");
          }
        });
      };
      
  
  
      const formatDate =(row, column) => {
          // 获取单元格数据
          let data = row[column.property]
          if(data == null) {
              return null
          }
          let dt = new Date(data)
          return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
        }
  
      const openCharge = (index,row) => {
        const dataa = {
          device_id : row.device_id,
          order_id:row.order_id
        }
        start_chage_yun(dataa).then((res) => {
          console.log(res);
          if (res) {
            data.loading = false;
            if (res.code == 200) {
              ElMessage.success("已开启")
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("数据获取失败");
          }
        });
      }
      
      
      const closeDialog = () => {
        data.Dialog.editLevy = false;
        
      };
      const torefund = (index, row) => {
        data.Dialog.editLevy = true;
        data.Dialog.DialogData = row;
        data.refunds.total = row.pay_money
        data.refunds.out_trade_no = row.order_id
        data.refunds.transaction_id = row.transaction_id
        data.refunds.refund_reason = row.refund_reason
      };
      const handlemoney = (value) => {
        data.values = value
        if(data.values == 0){
          data.isshow = false
          // data.refunds.refund = data.refunds.total

        }else if(data.values == 1){
          data.isshow = true
        }
      };

      
      const submitDialog = () => {
        if(data.values == 0){
          data.refunds.refund = data.refunds.total
         
        }else if(data.values == 1){
          data.refunds.refund = data.Dialog.DialogData.refund_fee
        }
        
          const dataa = data.refunds
          console.log(dataa,'dataaaaaaaaa');
          re_money1(dataa).then((res) => {
            if (res) {
            data.loading = false;
            if (res.code == 200) {
              ElMessage.success("退费成功")
              searchBtn()
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("退费失败");
          }
          
          });
        
        
      }
      const handleSizeChange = (val)=>{
        console.log(val,'vallllllhandleSizeChange')
        data.pageSize = val
      }
      const handleCurrentChange = (val)=>{
        console.log(val,'vallllllhandleCurrentChange')
          data.currentPage = val
          // 
      }
      
      const getUsername = () => {
        const username1 = localStorage.getItem('username')
        const users1 = []
        operator_data().then((res) => {
          data.users = res
          res.forEach((a)=>{    
            users1.push(a.username)
        })
        if(users1.indexOf(username1)==-1){
          data.isadmin = true
        }else{
          data.isadmin = false
          data.FormData.username = username1
        }
        });
      };
      onMounted(() => {
        getUsername()
        searchBtn();
      });
      return {
        ...toRefs(data),
        searchBtn,
        getUsername,
        // getList,
        // handleExcel,
        // handlePrint,
        openCharge,
        handleSizeChange,
        handleCurrentChange,
        formatDate,
        torefund,
        closeDialog,
        submitDialog,
        handlemoney,
        rounding
      };
    },
  };
  </script>
  
  <style>
  
  .formDiv{
    width: 75%;
  }
  .pagination{
      display: flex;
      justify-content: start;
      margin-left: 20px;
  }
  .pagination p{
  font-size: 14px;
  margin-right: 15px;
}
  .pagination span{
    color: red;
    
  }
  .bottomDiv {
      height: 100%;
    }
  .BtnSearch{
    float: left;
    width: 80px;
    margin: 0 4px;
  }
  .item1{
    margin-bottom: 5px !important;
  }
  .index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
  </style>